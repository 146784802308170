.container {
  background-color: var(--mantine-color-gray-0);
  border-radius: var(--mantine-radius-md);

  animation: pop 0.5s ease-in-out;
  padding: var(--mantine-spacing-s4) var(--mantine-spacing-s5);
}

@keyframes pop {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.9);
  }
  90% {
    transform: scale(1.05);
  }
  95% {
    transform: scale(0.97);
  }
  100% {
    transform: scale(1);
  }
}
