.navbarLink {
  padding-block: var(--mantine-spacing-s2);
  padding-inline: var(--mantine-spacing-s3);
  border-radius: var(--mantine-radius-sm);
  cursor: pointer;

  &:hover {
    background-color: var(--mantine-color-gray-1);
  }
}

.primaryLink {
  background-color: var(--mantine-color-gray-0);
}
